<template>
  <div
    class="lightbox-nav"
    :class="classObj"
    :style="styleObj"
    ref="icon-container"
  >
    <div class="lightbox-nav-inner" ref="icon-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="27"
        viewBox="0 0 16 27"
      >
        <polygon
          class="lightbox-nav-icon"
          points="-4.351 20.221 11.935 20.221 11.935 22.935 -7.065 22.935 -7.065 3.935 -4.351 3.935"
          transform="scale(-1 1) rotate(45 0 7.556)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lightbox-nav',

  props: {
    xPos: {
      type: Number,
      default: 0,
    },
    yPos: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String,
      default: 'next',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    posX() {
      let wrapper = this.$refs['icon-wrapper'];
      let offsetX = wrapper ? wrapper.offsetWidth / 2 : 0;
      return this.xPos + offsetX;
    },
    posY() {
      let wrapper = this.$refs['icon-wrapper'];
      let offsetY = wrapper ? wrapper.offsetHeight / 2 : 0;
      return this.yPos + offsetY;
    },
    styleObj() {
      return {
        left: this.posX + 'px',
        top: this.posY + 'px',
        visibility: this.isVisible ? 'visible' : 'hidden',
      };
    },
    classObj() {
      return [
        `lightbox-nav-${this.direction}`,
        {
          disabled: this.isDisabled,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';

$scale: 0.75;

.lightbox-nav {
  display: flex;
  z-index: 10;
  width: 56px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.65);
  cursor: none;
  pointer-events: none;
  animation: show-nav-arrow 125ms linear 1;
  transform: scale($scale) rotate(0);
  transition: transform 125ms linear;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: -28px 0 0 -28px;

  @include border-radius(50%);

  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.45);
  }

  > svg {
    fill: #000;
  }

  &.disabled {
    > svg {
      opacity: 0.25;
    }
  }

  &-next {
    transform: scale($scale) rotate(0turn);
  }

  &-prev {
    transform: scale($scale) rotate(-0.5turn);

    > svg {
      margin-left: 4px;
    }
  }
}
</style>
