import Cookies from 'js-cookie';
import getCookies from '@/util/cookies/get-cookies.js';
import config from '@/config/labs-config';
import getEnvironment from '@/util/get-environment';
import { randomString } from '@/util/string';

const FETCH_TIMEOUT = 2000;
const seedCookieName = 'seedId';
const env = getEnvironment();
const { experimentApi, featureFlagApi } = config(env);

const getSeedIdCookie = experimentId => {
  const cookieName = `${seedCookieName}-${experimentId}`;
  let cookieObj = getCookies(document.cookie, cookieName);
  const str = cookieObj[cookieName];
  let val = str && str != 'null' ? str : null;
  return val;
};

export const getCookieOptions = () => {
  const now = Date.now();
  const days = 30 * 24 * 60 * 60 * 1000;
  const expires = now + days;
  return {
    path: '/',
    expires,
    httpOnly: false,
  };
};

// id -> experiment id or feature-flag project id
const getSeedId = id => {
  let seedId = getSeedIdCookie(id);

  if (seedId) return seedId;

  seedId = randomString({ maxLength: 12, specialCharCount: 0 });
  const cookieOptions = getCookieOptions();

  const cookieName = `${seedCookieName}-${id}`;
  Cookies.set(cookieName, seedId, cookieOptions);
  return seedId;
};

// add required headers to API requests
const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

const getEndpoint = (endpoint, type) => {
  switch (type) {
    case 'experiment':
      return experimentApi[endpoint];
    case 'feature_flag':
      return featureFlagApi[endpoint];
  }
};

/**
 * Since the Fetch API doesn't have a proper timeout that we can use to catch errors
 * when an API is down, we need to use a setTimeout() to catch it.
 */
export const send = ({ endpoint, method = 'POST', payload = {} }) => {
  let didTimeOut = false;
  const headers = getHeaders();
  const { type } = payload;
  const url = getEndpoint(endpoint, type);

  const req = new Promise(function(resolve, reject) {
    const timeout = setTimeout(function() {
      didTimeOut = true;

      const ERR = new Error('Request timed out');

      //      log.error(`abc LABS > A/B > fetch timeout (${FETCH_TIMEOUT}ms):`, ERR);
      console.log(`abc LABS > A/B > fetch timeout (${FETCH_TIMEOUT}ms):`, ERR);

      reject(ERR);
    }, FETCH_TIMEOUT);

    fetch(url, {
      headers,
      method,
      body: JSON.stringify(payload),
    })
      .then(function(response) {
        clearTimeout(timeout);
        //        log.debug('LABS > clearTimeout');
        console.log('LABS > clearTimeout');
        if (!didTimeOut) {
          resolve(response);
        }
      })
      .catch(function(err) {
        //        log.error('LABS > A/B > fetch error:', err);
        console.log('LABS > A/B > fetch error:', err);

        // Rejection already happened with setTimeout
        if (didTimeOut) return;

        reject(err);
      });
  });
  return req
    .then(response => (response.json ? response.json() : response))
    .then(response => {
      if (response.code === 400) {
        throw response;
      }
      return response;
    })
    .catch(r => {
      //      log.error(`util/experiment > send > failed\nstatus: ${r.code}, ${r.message}`);
      console.log(
        `util/experiment > send > failed\nstatus: ${r.code}, ${r.message}`,
      );
      return r;
    });
};

export const getDecision = payload => {
  return send({ endpoint: 'decisionUrl', payload });
};

// calls the Labs decision engine to determine which treatment (variant) to show
export const getTreatment = async (id, type, key) => {
  const seedId = getSeedId(id);

  switch (type) {
    case 'experiment':
      return await getDecision({
        experimentId: id,
        seedId,
        type,
      });
    case 'feature_flag':
      return await getDecision({
        flagId: id,
        key,
        seedId,
        type,
      });
  }
};
