<template>
  <button class="btn-secondary" @click.stop="emitClickEvent">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitClickEvent() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';

.btn-secondary {
  @include gothamSsm();
  background-color: $white;
  border: 0;
  border-radius: 20px;
  color: #202429;
  font-size: 14px;
  padding: 0 space(2);
  margin-right: space(1);

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
</style>
