const qa = {
  experimentApi: {
    baseUrl: 'https://us1-test.disco-api.com',
    decision: '/labs/api/v1/decision',
    publish: '/labs/api/v1/events/publish',
    get decisionUrl() {
      return `${this.baseUrl}${this.decision}`;
    },
    get publishUrl() {
      return `${this.baseUrl}${this.publish}`;
    },
  },
  featureFlagApi: {
    baseUrl: 'https://us1-test.disco-api.com',
    decision: '/labs/api/v1/feature-flags/decision',
    get decisionUrl() {
      return `${this.baseUrl}${this.decision}`;
    },
    endpoint:
      'https://us1-test.disco-api.com/labs/api/v1/feature-flags/get-flag-by-key',
  },
};

const prod = {
  experimentApi: {
    baseUrl: 'https://us1-prod.disco-api.com',
    decision: '/labs/api/v1/decision',
    publish: '/labs/api/v1/events/publish',
    get decisionUrl() {
      return `${this.baseUrl}${this.decision}`;
    },
    get publishUrl() {
      return `${this.baseUrl}${this.publish}`;
    },
  },
  featureFlagApi: {
    baseUrl: 'https://us1-prod.disco-api.com',
    decision: '/labs/api/v1/feature-flags/decision',
    get decisionUrl() {
      return `${this.baseUrl}${this.decision}`;
    },
    endpoint:
      'https://us1-prod.disco-api.com/labs/api/v1/feature-flags/get-flag-by-key',
  },
};

export default function(env) {
  return env === 'prod' ? prod : qa;
}
