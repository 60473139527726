<template>
  <div v-cloak class="banner--inspiration" :class="bannerClasses">
    <div class="m-BannerImage">
      <contextual-title
        v-if="displayTitle"
        :title="title"
        :has-breadcrumb="isSelected"
        @breadcrumb-clicked="$emit('clear-feed')"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import ContextualTitle from '@common/contextual-title';

export default {
  name: 'Banner',
  components: {
    ContextualTitle,
  },
  props: {
    bannerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultTitle: 'Photo Inspiration',
    };
  },
  computed: {
    title() {
      return this.bannerData.title ? this.bannerData.title : this.defaultTitle;
    },
    isSearch() {
      return this.bannerData.isSearch;
    },
    hasFacets() {
      return this.bannerData.hasFacets;
    },
    hasContextualTitle() {
      return !!this.bannerData.title;
    },
    isSticky() {
      return this.bannerData.isSticky;
    },
    isSelected() {
      return this.hasContextualTitle || this.isSearch || this.hasFacets;
    },
    isCollapsed() {
      return this.isSticky || (this.hasFacets && !this.hasContextualTitle);
    },
    bannerClasses() {
      return {
        selected: this.isSelected,
        collapsed: this.isCollapsed,
      };
    },
    displayTitle() {
      return !this.isSticky;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';

.banner--inspiration {
  z-index: 31;
  margin-bottom: 52px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: $banner-height;
  width: auto;

  &.selected {
    margin-bottom: 125px;
    height: $banner-height-active;
    background-position: bottom;
    top: 0;
    .m-BannerImage {
      height: $banner-height-active;
      display: flex;
      align-items: center;
    }
  }

  &.collapsed {
    height: $banner-height-collapsed;
    .m-BannerImage {
      height: $banner-height-collapsed;
      background-color: #bae5ee;
    }
  }

  .m-BannerImage {
    width: 100vw;
    height: $banner-height;
    background-color: #bae5ee;
    overflow-x: hidden;
  }
}
</style>
