<template>
  <div class="o-PhotoGalleryPromo__m-ProductsList">
    <div class="m-ProductsList__m-Header">
      <svg
        class="icon-product"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12 13"
      >
        <path
          fill="#202429"
          d="M11.5,0.6h-5c-0.1,0-0.2,0-0.3,0.1l-2,2L3.5,3.4L0.2,6.7C0,6.9,0,7.1,0.2,7.3l5,5c0.1,0.1,0.4,0.1,0.5,0L9,8.9l0.8-0.7l2-2c0.1-0.1,0.1-0.2,0.1-0.3l0-5C11.8,0.7,11.7,0.6,11.5,0.6z M9.3,3.9c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S9.8,3.9,9.3,3.9z"
        />
      </svg>
      Shop the look
    </div>

    <div class="m-ProductsList__m-Body">
      <ul class="l-Columns">
        <li v-for="item in stlProducts" :key="item.id" :class="styles">
          <a
            class="m-ProductSmall__m-Link"
            target="_blank"
            rel="noskim"
            :href="addWayfairStlTag(item.product_url)"
            :title="item.name"
            @click.stop="itemClick(item)"
          >
            <div class="m-ProductSmall__m-TextWrap">
              <h4 class="m-ProductSmall__a-Headline">
                <span class="m-ProductSmall__a-HeadlineTex">{{
                  item.name
                }}</span>
              </h4>
              <div class="m-ProductSmall__a-Price">
                ${{ item.sale_price.toFixed(2) }}
              </div>
            </div>

            <div class="m-ProductSmall__m-ImageWrap">
              <img
                :class="{ 'on-feed': isOnFeed }"
                :src="item.both_dimensions"
              />
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="m-ProductsList__m-Footer">
      <div class="m-ProductsList__m-Partner">
        <span class="m-Partner__a-By">Powered By:</span>
        <a
          :href="addWayfairStlTag('https://www.wayfair.com')"
          target="_blank"
          rel="noskim"
          >wayfair.com</a
        >
      </div>
      <div class="m-ProductsList__a-LegalText">
        We may make 💰 from these links.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StlProducts',

  inject: ['products', 'trackProductLink'],

  props: {
    productId: {
      type: String,
      required: true,
    },

    isReversed: {
      type: Boolean,
      default: false,
    },

    isOnFeed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    stlProducts() {
      return (
        (this.products.filter(item => item.id === this.productId)[0] || {})
          .products || []
      );
    },

    styles() {
      return [
        'm-ProductsList__m-ProductSmall m-ProductSmall',
        !this.isReversed ? 'left' : 'right',
      ];
    },
  },

  methods: {
    itemClick(item) {
      this.trackProductLink(item);
    },

    addWayfairStlTag(url) {
      return (
        url + (/\?/.test(url) ? '&' : '?') + 'refid=HGTV12&ss_hgtv_product=1'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';

.o-PhotoGalleryPromo__m-ProductsList {
  background: #fff;
  box-shadow: none;
  width: 335px;
  height: 100%;
  position: relative;
  overflow: hidden;
  opacity: 1;
  visibility: visible;

  &:before {
    filter: none;
  }

  .m-ProductsList__m-Header {
    font-family: $font-family;
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
    min-height: 2rem;
    padding: 35px 24px 20px;
  }

  .m-ProductsList__m-Body {
    width: 100%;
    height: 488px;
    margin: 0;
    padding: 0 24px;
    overflow-y: auto;
    position: relative;

    .l-Columns {
      margin: 0;
      padding: 0;

      .m-ProductSmall {
        width: 100%;
        display: flex;
        margin: 0 0 0.5rem 0;

        .m-ProductSmall__m-Link {
          color: #202429;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;
        }

        &__m-TextWrap {
          padding: 0 5px;

          .m-ProductSmall__a-Headline {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5;
            width: 155px;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &__m-ImageWrap {
          position: relative;

          img {
            min-width: 72px;
            height: 72px;
            object-fit: cover;

            &.on-feed {
              min-width: 105px;
              height: 105px;
            }
          }
        }

        &.left {
          .m-ProductSmall__m-Link {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        &.right {
          .m-ProductSmall__m-Link {
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }

      &:last-child {
        margin-bottom: 74px;
      }
    }
  }

  .m-ProductsList__m-Footer {
    background: $white;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.085),
      0 -1px 5px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 74px;
    position: absolute;
    bottom: 0;
    padding: 12px 24px;
    z-index: 1;

    .m-ProductsList__m-Partner {
      font-family: $font-family;
      font-weight: bold;
      font-size: 0.75rem;
      color: #0fadc4;
      text-transform: uppercase;
      display: block;
      margin: 0;

      .m-Partner__a-By {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding-right: 7px;
        color: #939597;
      }
    }

    .m-ProductsList__a-LegalText {
      font-family: $font-family;
      font-size: 11px;
      color: #939597;
      margin: 0.25rem 0 0 0;
    }
  }
}
</style>
