export default {
  data() {
    return {
      forceBack: false,
    };
  },

  created() {
    window.addEventListener('popstate', this.onPopState);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
  },

  beforeRouteEnter(to, from, next) {
    next(vm => (vm.forceBack = false));
  },

  beforeRouteUpdate(to, from, next) {
    this.forceBack = to.params.dimensions === from.params.dimensions;
    next();
  },

  methods: {
    onPopState() {
      if (this.$refs.lightbox) {
        this.$refs.lightbox.closeLightbox();
        return;
      }

      if (this.$route.name === 'row' && !this.$route.params.dimensions) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.$route.name === 'dimension' && !this.$route.params.dimensions) {
        this.clearAll();
        this.forceBack = true;
      } else {
        this.forceBack && this.$router.back();
        this.forceBack = true;
      }
    },
  },
};
