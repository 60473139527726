var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showAd)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    intersection: {
      rootMargin: '300px 0px 0px 0px',
    },
    once: true,
  }),expression:"{\n    callback: visibilityChanged,\n    intersection: {\n      rootMargin: '300px 0px 0px 0px',\n    },\n    once: true,\n  }"}],class:_vm.classes},[_c('div',{staticClass:"dfp-ad__wrap"},[_c('div',{attrs:{"id":_vm.id,"data-dfp-type":_vm.tag}}),(_vm.isBigBox)?_c('span',{staticClass:"dfp-ad__label"},[_vm._v("Advertisement")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }