<template>
  <div
    v-if="facets.length > 0 || isSearch"
    class="navbar-brand selected-facets"
  >
    <button
      class="navbar-item clear-all button"
      @click="$emit('clearAllClick')"
    >
      Clear All
    </button>

    <button
      v-if="isSearch"
      class="navbar-item button"
      :class="{ 'search-query': isSearch }"
      @click.prevent="$emit('clearAllClick')"
    >
      {{ selectedSearchTerm | pictureDay }}
      <span class="icon is-small">
        <svg
          class="icon-remove"
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="7"
          viewBox="0 0 7 7"
        >
          <path
            fill="#202429"
            d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
          />
        </svg>
      </span>
    </button>
    <button-filter
      v-for="(facet, index) in facets"
      :key="index"
      selected
      :type="getFacetCategory(facet)"
      :title="facet | last | onlyChild | pictureDay"
      @click="$emit('facetClick', facet)"
    >
      {{ facet | last | onlyChild | pictureDay }}
    </button-filter>
  </div>
</template>

<script>
import buttonFilter from '@common/buttons/button-filter';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'facet-bar',

  components: {
    buttonFilter,
  },

  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },

    selectedSearchTerm: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('feed', ['facets']),
    ...mapGetters('feed', ['getFacetCategory']),
  },
};
</script>

<style lang="scss" scoped>
.navbar-brand {
  &.selected-facets {
    border-top: 1px solid #efefef;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 !important;
    padding: 0.75rem 0 !important;
    position: relative;
    z-index: 1;

    .search-query {
      margin: 0 12px;
    }
  }
}
</style>
