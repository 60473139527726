import { randomInt } from '../number';

/**
 *
 * @param {String} tag the string to which special charaters will be added
 * @param {Object} [options] options for inserting
 *   @property {Number} specialCharCount how many special charaters to insert; default: 3
 *   @property {String} specialChars the list of valid special characters; default: ~!@#$%^&*();:?{}[]|/
 */
export function insertSpecialChars(
  tag,
  { specialCharCount = 3, specialChars = '$-_.+!*(),' },
) {
  if (specialCharCount <= 0) {
    return tag;
  }
  for (let x = 0; x < specialCharCount; x++) {
    let charIdx = randomInt(0, specialChars.length - 1);
    let tagIdx = randomInt(0, tag.length);

    tag =
      tag.slice(0, tagIdx) +
      specialChars.substr(charIdx, 1) +
      tag.slice(tagIdx);
  }

  return tag;
}

/**
 * Returns a random string whose length is maxLength, including a number of special characters if specialCharCount is > 0
 * @param {Object} [options]
 *   @property {Int} maxLength length of the string you need; default is 20
 *   @property {Int} specialCharCount number of special characters to insert; default is 3
 */
export function randomString({
  maxLength = 20,
  specialCharCount = 3,
  specialChars = '$-_.+!*(),',
} = {}) {
  let tag = '';
  let len = 0;

  if (maxLength <= 0) {
    maxLength = 20;
  }

  if (specialCharCount < 0) {
    specialCharCount = 0;
  }

  const strLength = maxLength - specialCharCount;

  while (len < strLength) {
    let segment = Math.ceil(Math.random() * 10000).toString(20);
    tag = (tag + segment).substr(0, strLength);
    len = tag.length;
  }

  let left = tag.substr(4, 4);
  let right = tag.substr(12, 4);

  const tagWithUpperCase = tag
    .replace(left, left.toUpperCase())
    .replace(right, right.toUpperCase());

  tag = insertSpecialChars(tagWithUpperCase, {
    specialCharCount,
    specialChars,
  });
  return tag;
}
