<template>
  <row
    :is-split="display.isSplit"
    :is-reversed="display.isReversed"
    :has-gallery="display.hasGallery"
    :index="index"
    :route="route"
  >
    <div class="c-Feed--stl">
      <stl-products
        :product-id="productId"
        :is-reversed="display.isReversed"
        is-on-feed
      />

      <div
        class="c-Feed--item c-Feed--item--photo"
        :class="'card-image--' + backgroundIndex"
        :data-product-id="productId"
      >
        <FeedPhoto
          :image="image"
          :lazyload="lazyload"
          :data-track="image.track"
          :data-mdm="image.mdm"
          :photo-wrapper-class="{ 'c-Feed--photo-wrapper': true }"
          css-class="c-Feed--photo"
        />
      </div>
    </div>
  </row>
</template>

<script>
import row from '@common/row';
import stlProducts from '@common/stl-products';
import FeedPhoto from '@common/feed-photo';

export default {
  name: 'Stl',
  components: {
    row,
    stlProducts,
    FeedPhoto,
  },
  props: {
    rowType: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    display: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    image() {
      return this.images[0];
    },

    productId() {
      return this.image.shoppable ? this.image.productId : 0;
    },

    backgroundIndex() {
      let min = 1;
      let max = 6;
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    route() {
      let img = this.images.find(image => image.route);
      return (img && img.route) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';
.c-Feed {
  &--stl {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: $feed-gutter-half;
    background-color: #fff;

    .l-Reverse & {
      flex-direction: row-reverse;
    }

    .l-Split & {
      flex-direction: column-reverse;
    }

    .c-Feed--item {
      flex: 1;
      margin: 0;
      .photoLibraryLandingPage &,
      .photoLibraryViewerPage & {
        height: 635px;
      }
    }
  }
}
</style>
