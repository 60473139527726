<template>
  <div class="button-pinterest" @click.prevent.stop="emitClickEvent">
    <a
      data-pin-do="buttonPin"
      :data-pin-media="imageUrl"
      :data-pin-url="pinUrl"
      data-pin-tall="true"
      data-pin-round="true"
      :href="fullPinLink"
    >
      <img
        class="btn-secondary"
        src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_32.png"
      />
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('lightbox', ['active']),
    desc() {
      return (this.active && encodeURIComponent(this.active.desc)) || '';
    },
    imageUrl() {
      if (this.active && this.active.image && this.hasWindow) {
        return `${window.location.origin}${this.active.image}`;
      } else {
        return '';
      }
    },
    pinUrl() {
      if (this.hasWindow) {
        return `${this.active.url}`;
      } else {
        return '';
      }
    },
    fullPinLink() {
      return `https://www.pinterest.com/pin/create/button/
        ?url=${encodeURIComponent(this.pinUrl)}
        &media=${encodeURIComponent(this.imageUrl)}
        &description=${this.desc}`.replace(/\s+/g, '');
    },
  },

  methods: {
    emitClickEvent() {
      this.$emit('pinterest-clicked');
      if (this.hasWindow) {
        window.open(this.fullPinLink, 'pinWindow', 'width=800,height=600');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';
</style>
