<template>
  <div id="app" class="container">
    <LightBox
      v-if="hasActiveImage"
      :start-idx="activeIdx"
      @onTail="addImages"
      @track-click="trackModule"
      ref="lightbox"
    />
    <banner :banner-data="bannerData" @clear-feed="clearFeed()">
      <div v-if="!isSearch && facets.length < 1" class="feedSearch" />
      <transition name="slide-fade">
        <nav
          class="navbar"
          :class="[facets.length < 1 ? 'has-shadow' : '']"
          role="navigation"
          aria-label="main navigation"
        >
          <transition name="slide-fade">
            <div
              v-if="direction == 'up' || !sticky"
              class="navbar-brand navbar-buttons has-shadow"
            >
              <div class="buttons buttons-facets">
                <search
                  v-if="!facets.length || isSearch || noResults"
                  v-model="searchTerm"
                  :x-size="noResults || isSearch"
                  @confirm="
                    collapseFilters();
                    search({ resetPage: true });
                  "
                />
                <!--<button-facet
                  type="shop"
                  :is-active="selectedCategories.includes('shop')"
                  @toggleOn="shoppableClick"
                  @toggleOff="removeSelectedFacet('CUSTOM_FACET:SHOPPABLE')"
                >
                  Shop The Look
                </button-facet>-->

                <template v-if="!isSearch">
                  <button-facet
                    type="rooms"
                    :class="getFacetStyles('rooms')"
                    @click="
                      categoryClick({
                        title: 'rooms',
                        position: 1,
                        location: 'rooms',
                      })
                    "
                  >
                    Rooms
                  </button-facet>

                  <button-facet
                    type="styles"
                    :class="getFacetStyles('style')"
                    @click="
                      categoryClick({
                        title: 'style',
                        position: 2,
                        location: 'styles',
                      })
                    "
                  >
                    Styles
                  </button-facet>

                  <button-facet
                    type="colors"
                    :class="getFacetStyles('color')"
                    @click="
                      categoryClick({
                        title: 'color',
                        position: 3,
                        location: 'colors',
                      })
                    "
                  >
                    Colors
                  </button-facet>
                </template>
              </div>
              <transition appear @enter="enterFilters" @leave="leaveFilters">
                <div
                  v-if="isNavbarOpen"
                  :key="selectedCategory"
                  class="buttons buttons-filters"
                >
                  <anchor-filter
                    v-if="children.length"
                    :options="anchorFilterOptions"
                    desktop
                    @click="
                      categoryClick({
                        title: 'rooms',
                        position: 1,
                        location: 'rooms',
                      })
                    "
                  />
                  <template v-if="location === 'rooms'">
                    <template v-if="children.length < 1">
                      <button-facet
                        v-for="(item, index) in roomsParents"
                        :key="index"
                        type="rooms"
                        :is-active="isRoomsCategoryActive(item)"
                        :is-disabled="item.PageCount < 1"
                        @click="filterItemClick({ item, position: index })"
                      >
                        {{ item.Id | last | onlyChild | pictureDay }}
                      </button-facet>
                    </template>

                    <template v-for="(item, index) in filterItems">
                      <button-filter
                        v-if="children.length > 0 && item.parent"
                        :key="index"
                        type="rooms"
                        :is-active="isRoomsItemActive(item)"
                        :is-disabled="item.PageCount < 1"
                        @toggleOn="
                          filterChildClick({
                            item,
                            position: index,
                          })
                        "
                        @toggleOff="removeDesktopFacet(item.Value)"
                      >
                        {{ item.Id | last | onlyChild | pictureDay }}
                      </button-filter>
                    </template>
                  </template>

                  <template v-else>
                    <template v-if="children.length < 1">
                      <button-filter
                        v-for="(item, index) in filterItems"
                        :key="index"
                        :type="selectedCategory.split('-')[0]"
                        :title="item.Id | last | onlyChild | pictureDay"
                        :is-active="item.isActive"
                        :is-disabled="item.PageCount < 1"
                        @toggleOn="filterItemClick({ item, position: index })"
                        @toggleOff="removeDesktopFacet(item.Value)"
                      >
                        {{ item.Id | last | onlyChild | pictureDay }}
                      </button-filter>
                    </template>
                  </template>
                </div>
              </transition>
              <button
                v-if="isNavbarOpen"
                class="btn-toggle-filters"
                @click="collapseFilters"
              />
              <div
                v-if="isNavbarOpen"
                class="filters-overlay"
                @click="collapseFilters"
              />
            </div>
          </transition>

          <facet-bar
            :isSearch="isSearch"
            :selectedSearchTerm="selectedSearchTerm"
            @clearAllClick="clearAll"
            @facetClick="removeSelectedFacet($event)"
          />
        </nav>
      </transition>
    </banner>
    <section class="o-PromoSection">
      <facetPromo
        v-if="displayPromoFeed"
        :show-promo="facetPromoEnabled"
        path="src/components/common/facet-promo/index.desktop.vue"
        @update-facets="setRawFacets($event)"
        @update-search-term="updateSearchTerm($event)"
      />

      <browseFilters
        v-if="displayPromoFeed"
        path="src/components/common/browse-filters/index.desktop.vue"
        @update-search-term="updateSearchTerm($event)"
        @browse-filter="applyBrowseFilter($event)"
      />
    </section>
    <section class="o-FeedSection" :class="{ isLoading: isLoading }">
      <loader :is-loading="isLoading" />
      <h3 v-if="displayPromoFeed && !noResults" class="o-Gallery__a-Headline">
        Recent Photos
      </h3>
      <template v-if="!noResults">
        <main
          class="c-Feed"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
        >
          <template v-for="(row, index) in rows">
            <component
              :is="row.rowType"
              :key="index"
              v-bind="row"
              @track-click="trackClick"
              :index="index"
            />
            <advertisement
              v-if="index > 0 && (index + 1) % 3 == 0"
              :key="index + '-' + adTypes.leaderboard"
              :tag="adTypes.leaderboard"
            />
          </template>
        </main>
        <paginate
          :hide-pagination="this.hidePagination"
          :page-count="this.totalPages"
          :click-handler="toPage"
          :value="this.pageNum"
          :hide-prev-next="true"
          class="pagination"
          :container-class="'pagination-wrapper'"
          :page-url="this.pageUrl"
          :hash-props="this.hashProps"
          :is-mobile-device="false"
        />
      </template>
      <template v-else>
        <no-results :query="searchTerm" desktop />
      </template>
    </section>
  </div>
</template>

<script>
import appMixin from '@mixins/app';
import facetPromo from '@common/facet-promo';
import browseFilters from '@common/browse-filters';
import filterBar from '@mixins/filter-bar';
import row from '@common/row';
import standard from '@common/display-variants/standard';
import gallery from '@common/display-variants/gallery';
import stl from '@common/display-variants/stl';
import search from '@components/desktop/search';
import buttonFacet from '@common/buttons/button-facet';
import buttonFilter from '@common/buttons/button-filter';
import advertisement from '@common/advertisement';
import paginate from '@common/paginate';
import dfpTypes from '@constants/dfptype.js';
import banner from '@common/banner';
import { mapState, mapActions, mapGetters } from 'vuex';
import LightBox from '@desktop/lightbox/lightbox.vue';
import facetBar from '@desktop/facet-bar';
import backButtonMixin from '@mixins/back-button';
import {
  getFeatureFlag,
  getFeatureFlagIdByKey,
} from '@util/labs/feature-flag.js';
import { getTreatment } from '@util/labs/common.js';

export default {
  name: 'Desktop',
  components: {
    row,
    standard,
    gallery,
    stl,
    search,
    buttonFacet,
    buttonFilter,
    advertisement,
    banner,
    LightBox,
    facetPromo,
    facetBar,
    browseFilters,
    paginate,
  },
  mixins: [appMixin, filterBar, backButtonMixin],
  props: {
    term: {
      type: String,
      default: null,
    },
    dimensions: {
      type: String,
      default: '',
    },
    page: {
      type: String,
      default: '0',
    },
    rowIndex: {
      type: String,
      default: '0',
    },
    lightboxId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cardSel: 'c-Feed--row',
      isReversed: true,
      isFeatured: false,
      currentRow: {
        rowType: 'standard',
        images: [],
        display: {},
      },
      adTypes: {
        leaderboard: dfpTypes.LEADERBOARD.tag,
      },
      isNavbarOpen: false,
      isOverlayOn: false,
      selectedCategory: '',
      selectedItems: this.emptySelectedItems(),
      activeIdx: null,
      facetPromoEnabled: true,
      feedShown: false,
    };
  },

  provide() {
    return {
      products: this.products,
      callDynamicPageview: this.callDynamicPageview,
    };
  },

  computed: {
    ...mapState('feed', {
      activeImage: 'active',
    }),
    ...mapState('labs', ['experiments']),
    ...mapGetters('lightbox', ['hasActiveImage', 'lightboxEnabled']),
    availableRowImages() {
      return this.images.filter(image => !image.processed);
    },
    currentRowSize() {
      return this.currentRow.images.length;
    },
    rowTypeMap() {
      return {
        stl: {
          images: 1,
          display: {
            isSplit: false,
            isReversed: this.isReversed,
            hasGallery: false,
          },
        },
        gallery: {
          images: 2,
          display: {
            isSplit: true,
            isReversed: this.isReversed,
            hasGallery: true,
          },
        },
        standard: {
          images: 3,
          display: {
            isSplit: this.isFeatured ? true : false,
            isReversed: this.isReversed,
            hasGallery: false,
          },
        },
      };
    },
    bannerData() {
      return {
        title: this.contextualTitle,
        isSearch: this.isSearch,
        hasFacets: this.facets.length > 0,
        isSticky: this.sticky,
      };
    },
    displayPromoFeed() {
      return (
        this.facetPromoEnabled &&
        !(this.bannerData.hasFacets || this.bannerData.isSearch) &&
        !this.bannerData.title
      );
    },
  },

  watch: {
    images: {
      handler: function(images) {
        // Using the image watcher to create a psuedo-recursive row processing queue
        // This handler runs once for each image in the set
        let image;
        if (images && images.length && this.availableRowImages.length) {
          // Get the first image in the queue
          // If the row is empty, let first image dictate row type
          if (this.currentRowSize === 0) {
            image = this.getNextImage(this.availableRowImages);
            this.startRow(image);
          } else {
            // If the row is not empty, get the next image of type 'photo'
            // However, if there are no photos left, choose anything
            let rowType = this.currentRow.rowType;
            let getPhoto = true;
            if (rowType === 'gallery') {
              // Are there any photos left in the set?  If not, then set getPhoto to false to allow galleries instead
              getPhoto = this.getSetHasType(this.availableRowImages);
            }

            // if the current row has a gallery, then it's ok to add another gallery when there are no photos left.  Otherwise we can't use this gallery anywhere
            image = this.getNextImage(this.availableRowImages, getPhoto, false);

            // does this truncate the remainder? or only when we had forced photos to be part of the row?
            // what happens to incomplete rows?
            if (!image && this.availableRowImages.length >= 3) {
              this.resetCurrentRow();
              image = this.getNextImage(this.availableRowImages);
              this.startRow(image);
            }
          }
          if (image) {
            // Add image to current row
            this.currentRow.images.push(image);
            // Get number of images required to fill current row type
            const imagesRequired = this.rowTypeMap[this.currentRow.rowType]
              .images;
            // If this is now a valid row, push to rows and clear current row
            // ^^ maybe this should just check and not reset?
            this.checkRowValidity(this.currentRow, imagesRequired);
            // Mark image as processed
            const index = this.getImageIndex(images, 'url', image.url);
            // index can be false here:
            // marking the image will indicate that it is part of a row
            if (index === 0 || index) {
              this.processImage(index);
            }
          } else {
            console.log('No image was retrieved');
          }
        }
      },
      deep: true,
    },
    active(image) {
      if (this.active !== null) {
        this.activeIdx = image.index;
      } else {
        this.activeIdx = null;
      }
    },
  },

  created() {
    this.setLightbox(false);
    document.body.addEventListener('keydown', e => {
      if (e.keyCode === 27 && this.isNavbarOpen) {
        this.collapseFilters();
      }
    });
  },

  methods: {
    toPage(num) {
      let vm = this;
      vm.bottom = false;
      this.pageNum = parseInt(num);
      this.offset =
        this.pageNum <= 1
          ? 0
          : (this.pageNum - 1) * parseInt(this.numOfResults);
      this.clearImages();
      this.setRouteInfo();
      window.scrollTo(0, 0);
      this.addImages();
    },
    ...mapActions('feed', [
      'setDisplayIndex',
      'addRow',
      'addImagesByRow',
      'processImage',
    ]),
    ...mapActions('lightbox', ['setLightbox', 'setNextImage']),
    clearFeed() {
      this.clearSearch();
      this.setSearch();
      this.clearFacets();
      this.setLocation();
      this.resetNavScroll();
    },
    setRawFacets(facetArray) {
      if (facetArray.length) {
        this.facets = facetArray;
      }
    },
    toggleProp(name, active = false) {
      this[name] = !this[name];
      if (active) return this[name];
    },
    resetCurrentRow() {
      this.currentRow = {
        rowType: 'standard',
        images: [],
        display: this.rowTypeMap.standard.display,
      };
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        if (this.feedShown) return;
        else {
          console.log('Visibility changed', isVisible);
          // Initial visibility will cause a scroll if there is a lightbox image available (appReadyForScroll)
          // We don't want to change that again, hence the FeedShown flag will prevent further changes
          this.appReadyForScroll = true;
          this.feedShown = true;
        }
      }
    },
    getRowType({ type = 'photo' }) {
      //return shoppable ? 'stl' : type === 'gallery' ? type : 'standard';
      return type === 'gallery' ? type : 'standard';
    },
    getImageIndex(images, keyToMatch, valueToMatch) {
      const index = images.reduce((a, image, index) => {
        if (image[keyToMatch] == valueToMatch && !image.processed)
          a.push(index);
        return a;
      }, []);
      return index.length ? index[0] : false;
    },
    // Determine if the given type exists in the set.  Useful when we want to determine if there are any photos left for use
    getSetHasType(set, type = 'photo') {
      if (set && set.length) {
        return set.some(img => img.type === type);
      } else {
        return false;
      }
    },
    getNextImage(images, photo = false, allowShoppable = true) {
      if (photo) {
        const photos = images.filter(
          image => image.type == 'photo' && image.shoppable == allowShoppable,
        );
        return photos.length ? photos[0] : false;
      } else {
        return images.length ? images[0] : false;
      }
    },
    checkRowValidity(currentRow, imagesRequired) {
      let vm = this;
      if (currentRow.images.length == imagesRequired) {
        this.addRow(currentRow);

        let images = [...currentRow.images];
        if (currentRow.display.isReversed) {
          let img = images.shift();
          images.push(img);
        }

        if (currentRow.rowType === 'gallery') {
          images = images.reverse();
        }

        images.forEach(image => {
          let idx = vm.imagesByRow.length;
          vm.setDisplayIndex({ id: image.id, idx });
          if (image && image.id) {
            vm.addImagesByRow(image.id);
          }
        });

        this.resetCurrentRow();
        images = null;
      }
    },
    trackClick({ title, url }) {
      this.trackModule({ name: 'inspirationFeed', title, target: url });
    },
    toggleFilters() {
      this.showFilters('toggle', !this.isNavbarOpen);
    },

    changeFilters() {
      this.showFilters('change');
    },

    expandFilters() {
      this.showFilters('expand');
    },

    collapseFilters() {
      this.showFilters('collapse', false);
    },

    showFilters(action, isOpen = true) {
      this.isNavbarOpen = isOpen;
      this.filtersAction = action;

      this.fixPage(isOpen);
    },

    initOverlay() {
      this.isOverlayOn = true;
      window.addEventListener('wheel', this.collapseFilters, { ones: true });
    },

    enterFilters(el, done) {
      !this.isOverlayOn &&
        this.$anime({
          targets: '.filters-overlay',
          opacity: [0, 0.8],
          duration: 200,
          autoplay: true,
          easing: 'linear',
          complete: () => {
            this.initOverlay();
            done();
          },
        });

      this.$anime({
        targets: el,
        opacity: [0, 1],
        duration: 400,
        autoplay: true,
        easing: 'linear',
        complete: done,
      });
    },

    leaveFilters(el, done) {
      this.filtersAction !== 'change' &&
        this.$anime({
          targets: '.filters-overlay',
          opacity: 0,
          duration: 200,
          autoplay: true,
          easing: 'linear',
          complete: () => {
            this.isOverlayOn = false;
            done();
          },
        });

      this.$anime({
        targets: el,
        opacity: {
          value: 0,
        },
        height: {
          value: 0,
        },
        duration: this.filtersAction === 'change' ? 0 : 100,
        autoplay: true,
        easing: 'linear',
        complete: done,
      });
    },

    fixPage(isFixed = true) {
      if (isFixed) {
        document.documentElement.style.height = '100%';
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.height = 'auto';
        document.documentElement.style.overflow = 'visible';
      }
    },

    removeDesktopFacet(facet) {
      this.removeFacet(facet);

      const regNames = facet.match(/.*\/(.+)#/);

      let category = this.getFacetCategory(facet);

      if (category !== 'rooms') {
        category = category + 's';
      }

      if (regNames && regNames[1]) {
        this.selectedItems.names = this.selectedItems.names.filter(
          el => el !== regNames[1],
        );
      }

      if (this.selectedItems.categories[category]) {
        this.selectedItems.categories[category] = this.selectedItems.categories[
          category
        ].filter(item => item.Value !== facet);
      }

      this.isNavbarOpen && this.collapseFilters();

      if (!this.facets.length) {
        this.clearAll();
      }
    },

    emptySelectedItems() {
      return {
        categories: {},
        names: [],
      };
    },
    openLightbox() {
      if (this.lightboxEnabled) this.setNextImage();
    },

    // Configure the row to have a specific layout depending on the first element
    startRow(image) {
      const rowType = this.getRowType(image);
      this.currentRow.rowType = rowType;
      // Toggle display props every time to mix up the aesthetic
      this.toggleProp('isReversed');
      this.toggleProp('isFeatured');
      // TODO: Find a better way to toggle featured cards
      if (this.isFeatured) this.toggleProp('isReversed');
      this.currentRow.display = this.rowTypeMap[rowType].display;
    },
    async fetchFeatureFlag(featureFlagKey) {
      const { projectId, flagId: featureFlagId } = getFeatureFlagIdByKey(
        featureFlagKey,
      );
      let featureFlagData, status;

      featureFlagData = await getFeatureFlag(projectId, featureFlagKey);
      ({
        metadata: { status },
      } = featureFlagData);
      const {
        config: { feature },
      } = await getTreatment(featureFlagId, 'feature_flag', featureFlagKey);
      const shouldHideOverlay =
        status === true && feature.toLowerCase() === 'remove-overlay';
      if (this.lightboxEnabled) {
        this.setLightbox(!shouldHideOverlay);
        if (shouldHideOverlay) {
          console.log(
            `Labs - Feature Flag ${featureFlagKey} showing TREATMENT - hide overlay`,
          );
        } else {
          console.log(`Labs - Feature Flag ${featureFlagKey} showing CONTROL`);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@style/variables/index.scss';

.c-Feed {
  margin: 0 auto;
  width: $feed-width;
  min-height: 100vh;
  display: block;
}

nav {
  flex-direction: column;
  width: auto;

  .navbar-brand {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05),
      0 3px 3px 1px rgba(0, 0, 0, 0.02);
    justify-content: center;
    width: 100%;

    &.navbar-buttons {
      flex-direction: column;
    }

    .buttons {
      justify-content: center;
      z-index: 1;
    }

    .buttons-facets {
      border: none;
      max-width: 1280px;
      width: 100%;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      position: relative;
    }

    .buttons-filters {
      margin: -4px 0 0 0;
      padding-bottom: 12px;
    }
  }
}

.btn-toggle-filters {
  background: #fff;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  border-width: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 3px 3px 1px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 27px;
  position: absolute;
  bottom: -26px;
  cursor: pointer;
  z-index: 2;

  &:after {
    content: '';
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }
}

.filters-overlay {
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.5;
}

.card--col2 {
  flex-basis: 63%;
}

svg {
  &.icon-product {
    margin-right: 2px;
  }

  &.icon-remove {
    margin: 2px 0 1px 4px;
  }
}

.is-small {
  svg.icon-backArrow {
    margin: 0;
  }
}

.is-search {
  svg.icon-search {
    margin: 0 8px 0 -5px;
  }
}

.navbar {
  .anchor-filter {
    margin-right: 0;
  }
}

.navbar-home {
  background: #fff;
  height: 52px;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 8px;
}

.a-ContextualTitle {
  margin-top: 1.5rem;
}

.no-scroll {
  position: absolute;

  &.photoLibraryLandingPage,
  &.photoLibraryViewerPage {
    overflow: hidden !important;
  }
}

.o-Gallery__a-Headline {
  @media only screen and (min-width: 994px) {
    font-size: 36px;
    font-weight: 300;
    width: auto;
    min-width: 994px;
    max-width: 1250px;
    margin: 30px auto;
    padding: 1rem 0 1rem 1rem;
  }
}

.o-FeedSection {
  position: relative;
  padding-top: $feed-gutter-half;
  &.isLoading {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 29;
      background: #fff;
      opacity: 0.9;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  li {
    margin: 0 3px;
    padding: 12px;
    min-width: 6px;
    height: 19px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #000000;
    }
    &.active {
      border: 2px solid #0fadc4;
      padding: 12px 9px;
      margin-top: -2px;
    }
    &.active a,
    &.disabled a {
      cursor: pointer;
    }
    &:first-child,
    &:last-child {
      text-transform: uppercase;
    }
  }
  .pagination-wrapper {
    display: flex;
    margin-bottom: 40px;
  }
}
</style>
