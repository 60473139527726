import cookie from 'cookie';

const getCookies = (cookieStr, keys = []) => {
  if (!cookieStr) {
    return {};
  }
  const cookies = cookie.parse(cookieStr);

  keys = typeof keys === 'string' ? [keys] : keys;

  let result = keys.length ? {} : cookies;
  if (keys.length > 0) {
    keys.forEach(key => (result[key] = cookies[key] || null));
  }
  return result;
};

export default getCookies;
