<template>
  <transition name="pl-lightbox">
    <section
      v-if="this.lightboxEnabled"
      :class="containerClass"
      class="pl-lightbox"
      @click="clickHandler"
      ref="pl-lightbox"
    >
      <btn-close @close="closeLightbox" ref="button-close" />
      <div
        class="pl-lightbox-wrapper"
        ref="lightbox-wrapper"
        v-touch:swipe.left="next"
        v-touch:swipe.right="prev"
      >
        <div class="pl-lightbox-container" ref="pl-lightbox-container">
          <!--<div
          class="pl-lightbox-container"
          ref="pl-lightbox-container"
          :class="{ shoppable }"
        >
          <stl-products
            v-if="shoppable"
            :product-id="productId"
            :is-reversed="false"
          />-->
          <lightbox-image
            :image="active"
            target="_self"
            lazyload="true"
            @track-click="$emit('track-click', $event)"
          />
        </div>
      </div>
      <Advertisement
        class="pl-lightbox-ad"
        :tag="adTypes.bigbox"
        ref="lightboxAd"
        :isLightboxAd="true"
      />
      <LightboxNav
        class="lightbox-nav-next on-hover"
        :xPos="navX"
        :yPos="navY"
        :direction="direction"
        :isVisible="isNavVisible"
        :isDisabled="isNavDisabled"
      />
    </section>
  </transition>
</template>

<script>
import Advertisement from '@common/advertisement';
import BtnClose from '@common/buttons/button-close.vue';
import LightboxImage from './lightbox-image.vue';
import LightboxNav from './lightbox-nav';
import { mapActions, mapState, mapGetters } from 'vuex';
import DFP_TYPE from '@constants/dfptype.js';

const lightboxPattern = /(lightbox\/)(.*)$/g;

export default {
  components: {
    BtnClose,
    LightboxImage,
    LightboxNav,
    Advertisement,
  },

  inject: ['callDynamicPageview', 'getMDM', 'trackProductImpression'],

  data() {
    return {
      adTypes: {
        bigbox: DFP_TYPE.BIGBOX.tag,
      },
      preventScrollClass: 'no-scroll',
      navX: 0,
      navY: 0,
      isNavVisible: true,
      isNavDisabled: false,
      direction: 'next',
      clickHandler: () => {
        this[this.direction];
      },
      screenHeight: null,
    };
  },

  computed: {
    ...mapState('feed', ['scrollPos']),
    ...mapState('lightbox', ['active']),
    ...mapGetters('lightbox', [
      'first',
      'last',
      'hasActiveImage',
      'lightboxEnabled',
    ]),
    ...mapState(['route']),
    shoppable() {
      return this.active && this.active.shoppable;
    },
    productId() {
      return this.shoppable && this.active.productId
        ? this.active.productId
        : 0;
    },
    isSmallScreen() {
      return this.screenHeight <= 800;
    },
    containerClass() {
      return {
        'small-screen': this.isSmallScreen,
      };
    },
  },

  watch: {
    active(active) {
      if (active) {
        this.setLightboxId(active);
      }
    },
  },

  created() {
    this.toggleBodyClass('add', this.preventScrollClass);
    if (this.hasActiveImage) {
      this.setLightboxId(this.active);
    }
    if (this.hasWindow) {
      document.body.addEventListener('keydown', this.keyDownHandler);
      this.screenHeight = window.innerHeight || window.clientY;
      window.addEventListener('resize', () => {
        this.screenHeight = window.innerHeight || window.clientY;
      });
    }
  },

  mounted() {
    window.addEventListener('mousemove', this.mousemoveHandler);
    this.wrapper = this.$refs['pl-lightbox'];
    this.img = document.querySelector('.lightbox-image__img');

    this.trackImpression();
    this.callDynamicPageview();

    // Optional; A parent element to search for new ad slots
    var parent = this.wrapper;
    // Optional; If the change would be considered a new page(new content, SPA navigation, url change, etc)
    var isNewPage = false;

    if (parent)
      window.DatMCP &&
        window.DatMCP.execute(function(DatMCP) {
          DatMCP.update(parent, isNewPage);
        });
  },

  beforeDestroy() {
    document.body.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('mousemove', this.mousemoveHandler);
  },

  methods: {
    ...mapActions('lightbox', ['setNextImage', 'setActiveImage']),

    next() {
      this.setNextImage();
      this.$refs.lightboxAd.refreshAd();

      if (this.active === this.last) {
        this.$emit('onTail');
      }

      this.trackClick('Next');
      this.trackImpression();
      this.callDynamicPageview();
    },

    prev() {
      this.setNextImage(-1);
      this.$refs.lightboxAd.refreshAd();

      this.trackClick('Prev');
      this.trackImpression();
      this.callDynamicPageview();
    },

    closeLightbox() {
      this.setActiveImage();
      this.setLightboxId();
      this.toggleBodyClass('remove', this.preventScrollClass);

      this.trackClick('Close');
    },

    toggleBodyClass(action = 'add', className) {
      document.body.classList[action](className);
    },

    setLightboxId(image) {
      let path;
      if (image) {
        if (this.route.params.lightboxId) {
          path = this.route.path.replace(lightboxPattern, '$1' + image.id);
        } else {
          path = `${this.route.path}lightbox/${image.id}`;
        }
      } else {
        path = this.route.path.replace(lightboxPattern, '');
      }
      this.$router.push({ path });
    },

    keyDownHandler(e) {
      if (e) {
        switch (e.keyCode) {
          case 27:
            this.closeLightbox();
            break;
          case 37:
            this.prev();
            this.direction = 'prev';
            this.isNavDisabled = this.active === this.first;
            break;
          case 39:
            this.next();
            this.direction = 'next';
            this.isNavDisabled = this.active === this.last;
            break;
        }
      }
    },

    mousemoveHandler(e) {
      this.navX = e.clientX;
      this.navY = e.clientY;

      let cursorThreshhold = Math.floor(this.wrapper.offsetWidth / 2);

      this.direction = this.navX < cursorThreshhold ? 'prev' : 'next';

      this.isNavVisible = this.showNavIcon(e.target);

      this.isNavDisabled =
        (this.active === this.first && this.direction === 'prev') ||
        (this.active === this.last && this.direction === 'next');

      this.clickHandler = this[this.direction];
    },

    showNavIcon($target) {
      if (!$target) {
        return false;
      }
      let safeTargets = [
        this.$el,
        this.img,
        this.wrapper,
        this.$refs['lightbox-wrapper'],
        this.$refs['pl-lightbox-container'],
        this.$refs['button-close'],
      ];

      let thisTarget = safeTargets.find(t => t === $target);
      const className = $target && $target.className;
      const isLightboxImageEl =
        className &&
        typeof className === 'string' &&
        className.indexOf('lightbox-image') > -1;

      return !!thisTarget || isLightboxImageEl;
    },

    trackClick(name) {
      this.$emit('track-click', {
        name: `inspirationFeed--Lightbox-${name}`,
        target: window.location.href,
      });
    },

    trackImpression() {
      this.productId &&
        this.trackProductImpression(this.getMDM(this.productId));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';

$wrapper-padding: 0.5rem;
$stl-width: 295px;
$reserved-bb-spacing: 322px;

.pl-lightbox {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  cursor: none;

  &-wrapper {
    align-self: center;
    max-height: 100vh;
    position: relative;
  }

  &-container {
    width: auto;
    min-width: 336px;
    height: auto;
    background-color: transparent;
    border-radius: 2px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 $reserved-bb-spacing 0 0;

    // WIDTHS:
    // not shoppable: margins both sides: 1295px
    // not shoppable: margins 1 side:      973px
    // shoppable: margins both sides      1630px
    // shoppable: margins 1 side:         1263px

    // not shoppable
    @media screen and (min-width: 1295px) {
      &:not(.shoppable) {
        margin: 0 $reserved-bb-spacing;
      }
    }

    &.shoppable {
      @media screen and (min-width: 1630px) {
        margin: 0 $reserved-bb-spacing;
      }
    }

    &-nav-left,
    &-nav-right {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;

      .lightbox-nav {
        display: none;
      }

      &:hover {
        .lightbox-nav {
          display: flex;
        }
      }
    }
    &-nav-left {
      left: 0;
      width: 25%;
      height: 100%;
    }
    &-nav-right {
      right: 0;
      width: 75%;
      height: 100%;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .o-PhotoGalleryPromo__m-ProductsList {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    align-self: center;
    min-width: $stl-width;
    cursor: default !important;
  }

  .button-close {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1000;
  }

  &-ad {
    min-width: 300px;
    align-self: center;

    position: absolute;
    top: 50%;
    right: $wrapper-padding;
    transform: translate(0, -50%);
  }

  &.small-screen {
    overflow-y: auto;
    padding-top: 82px;

    .pl-lightbox-wrapper {
      max-height: none;
      min-height: 800px;
      height: auto;
      padding-top: 82px;
    }

    .pl-lightbox-ad {
      top: 82px;
      transform: none;
    }
  }
}
</style>
