<template>
  <div class="button-close" @click.stop="emitClickEvent">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <path
        id="path-1"
        fill="#000"
        d="M10.6066017,9.19238816 L18.3847763,16.9705627 L16.9705627,18.3847763 L9.19238816,10.6066017 L1.41421356,18.3847763 L3.55271368e-15,16.9705627 L7.77817459,9.19238816 L0,1.41421356 L1.41421356,3.55271368e-15 L9.19238816,7.77817459 L16.9705627,1.77635684e-15 L18.3847763,1.41421356 L10.6066017,9.19238816 Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  methods: {
    emitClickEvent() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@app-styles';

.button-close {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  width: 56px;
  height: 56px;

  &:hover {
    background-color: $white;
    cursor: pointer;
  }
}
</style>
