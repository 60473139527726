const dfpTypes = {
  BIGBOX: { tag: 'dfp_bigbox', sizes: ['300x250', '300x600'] },
  LEADERBOARD: {
    tag: 'dfp_leaderboard',
    sizes: ['728x90'],
  },
  PROG_BIGBOX: {
    tag: 'dfp_prog_bigbox',
    sizes: ['300x252', '300x602', '300x1052'],
  },
  SMARTPHONE_BANNER: { tag: 'dfp_smartphone_banner', sizes: ['320x50'] },
  UTILITY1: { tag: 'dfp_utility1', sizes: ['1x6'] },
  UTILITY2: { tag: 'dfp_utility2', sizes: ['1x12'] },
};

export default dfpTypes;

export const events = {
  PAGE_VIEW: 'event1',
};
