<template>
  <div
    v-if="showAd"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '300px 0px 0px 0px',
      },
      once: true,
    }"
    :class="classes"
  >
    <div class="dfp-ad__wrap">
      <div :id="id" :data-dfp-type="tag" />
      <span v-if="isBigBox" class="dfp-ad__label">Advertisement</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Globals } from '@helpers';
import DFP_TYPE from '@constants/dfptype.js';

export default {
  name: 'Advertisement',
  props: {
    tag: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'page',
    },
    preload: {
      type: Boolean,
      default: false,
    },
    isLightboxAd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this.requiresUniqueId ? '' : this.tag,
      slotName: null,
      sa: null,
      isLoaded: false,
      showAd: true,
    };
  },
  computed: {
    ...mapGetters('lightbox', ['hasActiveImage']),
    classes() {
      let css = {
        'dfp-ad': true,
        'disable-ad-refresh': this.isLightboxAd
          ? !this.hasActiveImage
          : this.hasActiveImage,
      };
      if (this.tag.length) {
        let className = this.tag.replace('_', '-');
        css[className] = true;
      }
      return css;
    },
    isBigBox() {
      return this.tag ? this.tag.includes(DFP_TYPE.BIGBOX.tag) : false;
    },
    requiresUniqueId() {
      return this.isBigBox || this.isLeaderBoard([this.tag]);
    },
  },
  watch: {
    preload(value) {
      if (value && !this.isLoaded) {
        this.initialAdLoad();
      }
    },
    tag() {
      let slotEl = Globals.document.querySelector(`#${this.slotName}`);
      slotEl.parentNode.removeChild(slotEl);

      if (this.type) {
        this.removeSlot({ type: this.type, slot: this.slotName });
      }

      if (this.requiresUniqueId) {
        this.id = this.getId();
      }
      this.initialAdLoad();
    },
  },
  beforeDestroy() {
    if (this.type) {
      this.removeSlot({ type: this.type, slot: this.slotName });
    }
  },
  mounted() {
    let vm = this;
    if (vm.isBigBox || vm.isLeaderBoard([this.tag])) {
      vm.id = vm.getId();
    }
  },
  methods: {
    ...mapActions({
      addSlot: 'advertisement/addSlot',
      leaderboardHasLoaded: 'advertisement/leaderboardHasLoaded',
      removeSlot: 'advertisement/removeSlot',
      refreshSlots: 'advertisement/refreshSlots',
    }),

    initialAdLoad() {
      let vm = this;
      vm.isLoaded = true;

      if (Globals && Globals.hasWindow() && Globals.window.SniAds) {
        vm.sa = Globals.window.SniAds;

        vm.$nextTick(async () => {
          try {
            vm.listenForComplete();
            vm.slotName = await vm.loadAd();

            if (vm.type) {
              vm.addSlot({
                type: vm.type,
                name: vm.slotName,
                id: vm.id,
              });
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Error mounting ${vm.tag}, ${e.message}`);
          }
        });
      }
    },

    loadAd() {
      let vm = this;
      return new Promise((resolve, reject) => {
        if (vm.sa) {
          vm.sa.ready(() => {
            if (
              Globals &&
              Globals.hasWindow() &&
              Globals.window.SniAds &&
              Globals.window.SniAds.getSlotConfig
            ) {
              if (Globals.window.SniAds.getSlotConfig(vm.tag)) {
                let slotName = null;
                if (vm.isBigBox || vm.isLeaderBoard([vm.tag])) {
                  slotName = vm.sa.appendSlot(vm.id, vm.tag);
                }
                resolve(slotName);
              }
            } else {
              reject(new Error(`No config for slot: ${vm.tag}`));
            }
          });
        } else {
          reject(new Error(`SniAds doesn't exist`));
        }
      });
    },

    refreshAd() {
      this.refreshSlots([this.slotName]);
    },

    getId() {
      return `${this.tag}_${this.getUniqueId()}`;
    },

    getUniqueId() {
      let id = '';
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
      const idLength = 8;

      for (var i = 0; i < idLength; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },

    getAdSlotId(slot) {
      return (
        (slot &&
          slot.slot &&
          slot.slot.getSlotElementId &&
          slot.slot.getSlotElementId()) ||
        null
      );
    },

    getAdType(slot) {
      if (!slot) {
        return;
      }
      let slotId = this.getAdSlotId(slot);
      let idParts = (slotId && slotId.split('_')) || false;
      let adType = null;

      if (idParts && idParts.length && idParts.length > 1) {
        let lastPart = idParts.pop();
        if (isNaN(lastPart)) {
          idParts.push(lastPart);
        }
        adType = idParts.join('_');
      }
      return adType;
    },

    listenForComplete() {
      let vm = this;
      const lbHandler = adSlot => {
        let adType = vm.getAdType(adSlot);
        if (vm.isLeaderBoard([this.tag, adType])) {
          if (Globals && Globals.hasDocument()) {
            let slotEl = Globals.document.querySelector(`#${vm.slotName}`);
            let height = (slotEl && slotEl.offsetHeight) || 0;
            vm.leaderboardHasLoaded(height > 0);
          }
        }
      };

      Globals.window.SniAds.Event.subscribe('slotRenderComplete', lbHandler);
    },

    visibilityChanged(isVisible) {
      if (isVisible && !this.isLoaded) {
        this.initialAdLoad();
      }
    },

    isLeaderBoard(arr) {
      return (
        arr.findIndex(
          t =>
            t === DFP_TYPE.LEADERBOARD.tag ||
            t === DFP_TYPE.SMARTPHONE_BANNER.tag,
        ) >= 0
      );
    },
  },
};
</script>

<style lang="scss">
.dfp-ad__label {
  text-align: center;
  width: 100%;
  display: block;
}

.disable-ad-refresh {
  visibility: hidden;
}
</style>
