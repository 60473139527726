<template>
  <div class="lightbox-image">
    <div
      class="lightbox-image__container"
      :class="classObj"
      :data-img-index="imgIndex"
    >
      <div class="lightbox-image__container-inner">
        <transition name="fade">
          <img
            v-if="showImage"
            :src="src"
            :data-src="image.image"
            :alt="title"
            class="lightbox-image__img"
          />
        </transition>
      </div>
      <div class="lightbox-image__actions">
        <btn-secondary
          v-if="showDetails"
          :text="buttonText"
          @clicked="openPhotoDetailsPage"
        />
        <btn-pinterest @pinterest-clicked="trackClick('Pinterest')" />
      </div>
    </div>
    <div v-if="credit" class="lightbox-image__info">
      <p class="lightbox-image__credit">
        {{ credit }}
      </p>
    </div>
  </div>
  <!--
    sample image url's
    ---
    Landscape:
    https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2017/5/22/0/BP_HHMTN111H_Hayes_master-bathroom_detail_clawfoot-tub_243961_876956-1419544.jpg.rend.hgtvcom.1280.853.suffix/1495547263355.jpeg
    ---

    Portrait:
    https://images.unsplash.com/photo-1531835551805-16d864c8d311?ixlib=rb-1.2.1&auto=format&fit=crop&w=3334&q=80
  -->
</template>

<script>
import BtnSecondary from '@common/buttons/button-secondary.vue';
import BtnPinterest from '@common/buttons/share/button-pinterest.vue';
import { hasWindow } from '@mixins/globals';

export default {
  components: {
    BtnSecondary,
    BtnPinterest,
  },
  props: {
    image: {
      type: Object,
      default: () => null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data() {
    return {
      dataSrc:
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E",
      src: null,
      showImage: false,
    };
  },

  computed: {
    title() {
      return (this.image && this.image.title) || '';
    },
    desc() {
      return (this.image && this.image.desc) || '';
    },
    showDetails() {
      return this.image && this.image.url;
    },
    href() {
      return (this.image && this.image.url) || '/';
    },
    credit() {
      return (this.image && this.image.credit) || '';
    },
    buttonText() {
      return this.image && this.image.type === 'gallery'
        ? 'Collection Details'
        : 'Photo Details';
    },
    imgIndex() {
      return String(this.image.index);
    },

    classObj() {
      return { 'show-img-index': this.showImageIndex };
    },
  },
  watch: {
    image: {
      immediate: true,
      handler() {
        this.loadImage();
      },
    },
  },
  methods: {
    loadImage() {
      let vm = this;
      vm.showImage = false;
      let i = new Image();
      this.src = this.dataSrc;
      i.onload = () => {
        this.src = vm.image.image;
        this.showImage = true;
      };
      i.src = vm.image.image;
    },
    openPhotoDetailsPage() {
      this.trackClick('Details');

      if (hasWindow()) {
        window.open(this.href, this.target);
      }
    },

    trackClick(name) {
      this.$emit('track-click', {
        name: `inspirationFeed--Lightbox-${name}`,
        target: window.location.href,
      });
    },
  },
};
</script>

<style lang="scss">
@import '@app-styles';

.lightbox-image {
  background-color: transparent;
  width: auto;
  max-width: 54vw;
  align-self: center;

  &__container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    position: relative;

    &-inner {
      position: relative;
    }
  }

  &__info {
    margin-top: space(2);
  }

  &__img {
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: auto;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
    height: space(4);
  }

  &__credit {
    @include gothamSsm();
    font-size: 12px;
    line-height: 15px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
