<template>
  <div class="m-TitleContainer">
    <a
      v-if="hasBreadcrumb"
      :href="itemUrl()"
      class="a-Breadcrumb"
      @click.prevent="$emit('breadcrumb-clicked')"
      >Photo Inspiration</a
    >
    <h1 class="a-ContextualTitle">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'ContextualTitle',
  props: {
    title: {
      type: String,
      default: 'Photo Inspiration',
    },
    hasBreadcrumb: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemUrl() {
      return window.location.protocol + '//' + window.location.host;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';

.m-TitleContainer {
  width: $navbar-min-width;
  display: flex;
  margin: 0 auto;
  position: relative;
}

.a-ContextualTitle {
  position: relative;
  padding-left: 2rem;
  margin-top: 0;
  bottom: -2rem;

  .selected & {
    text-align: start;
    bottom: -1.5rem;
  }
}

.a-Breadcrumb {
  position: absolute;
  display: inline-block;
  margin-top: 1.8rem;
  padding-left: 2.1rem;
  font-weight: $font-weight-bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: $breadcrumb-color;
  text-transform: uppercase;
  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
  .selected & {
    margin-top: 0;
  }
}
</style>
