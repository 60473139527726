import config from '@/config/labs-config';
import getEnvironment from '@/util/get-environment';
import featureFlagsJson from '@/labs/feature-flags.json';

const env = getEnvironment();

const { endpoint } = config(env).featureFlagApi;

export const getFeatureFlag = (projectId, key) => {
  const url = `${endpoint}/${projectId}/${key}`;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(function(error) {
      throw error;
    });
};

export const getFeatureFlagIdByKey = key => {
  if (!key || typeof key !== 'string') return;
  const { projectId, flagId } = featureFlagsJson[key][env];
  return { key, projectId, flagId };
};
