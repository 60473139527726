<template>
  <div class="search" :class="{ 'x-size': xSize }">
    <div class="button search-icon">
      <svg
        class="icon-search"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18"
        height="18"
        viewBox="0 0 14 15"
        aria-hidden="true"
      >
        <path
          stroke="#C2C3C4"
          stroke-width="2"
          d="M9.8503221,10.6738515 L9.58623458,10.409764 L10.202427,9.70567998 C11.034395,8.75504275 11.5,7.53994282 11.5,6.25 C11.5,3.35050506 9.14949494,1 6.25,1 C3.35050506,1 1,3.35050506 1,6.25 C1,9.14949494 3.35050506,11.5 6.25,11.5 C7.19647716,11.5 8.10404274,11.2500948 8.90140371,10.7824532 L9.56798354,10.391513 L9.8503221,10.6738515 Z M6.25,11.5 C3.35050506,11.5 1,9.14949494 1,6.25 C1,3.35050506 3.35050506,1 6.25,1 C9.14949494,1 11.5,3.35050506 11.5,6.25 C11.5,9.14949494 9.14949494,11.5 6.25,11.5 Z"
        />
        <path
          id="path-1"
          d="M10.9549407,10.3642565 L13.4718127,12.8811285 C13.862337,13.2716528 13.862337,13.9048178 13.4718127,14.2953421 C13.0812884,14.6858664 12.4481234,14.6858664 12.0575991,14.2953421 L9.40730284,11.6450458 C8.48074857,12.1884576 7.4017566,12.5 6.25,12.5 C2.79822031,12.5 0,9.70177969 0,6.25 C0,2.79822031 2.79822031,0 6.25,0 C9.70177969,0 12.5,2.79822031 12.5,6.25 C12.5,7.82557415 11.9169934,9.26497982 10.9549407,10.3642565 Z M6.25,10.5 C8.59721019,10.5 10.5,8.59721019 10.5,6.25 C10.5,3.90278981 8.59721019,2 6.25,2 C3.90278981,2 2,3.90278981 2,6.25 C2,8.59721019 3.90278981,10.5 6.25,10.5 Z"
        />
      </svg>
    </div>
    <div class="search-field">
      <input
        class="input"
        type="text"
        placeholder="Search HGTV Photo Inspiration"
        aria-label="input to search HGTV Photo Inspiration"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keyup.enter="$emit('confirm')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',

  props: {
    value: {
      type: String,
      default: '',
    },

    xSize: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc((100% - 628px) / 2);
  position: absolute;
  left: 36px;

  &.x-size {
    width: calc((100% - 264px) / 2);
  }

  &-icon {
    border: none;
    margin: 0 !important;
    padding: 0;
    cursor: default;
  }

  &-field {
    border: none;
    outline: none;
    width: 100%;
    padding: 0;

    .input {
      font-family: Gotham SSm, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 12px;
      color: #202429;
      border: none;
      outline: none;
      line-height: 1;
      height: 36px;

      &::placeholder {
        color: #202429;
        text-align: left;
      }

      &:focus::placeholder {
        color: #a0a0a0;
      }
    }
  }
}
</style>
